import Facebook_icon from '../images/Facebook.svg';
import Google_icon from '../images/google.svg';


const ReviewsData = [
{
    id: '1',
    person_name : 'Sami I.',
    rating: '4',
    content: `Honestly the best way to explain this company is “Clutch”. Last minute request, they saved me twice now and no questions asked. Professional to the max. Convenient. Honestly the price you’re paying for the service you get is unfathomable. This is also coming from someone who is very difficult to please so just know it’s a high class service.`,
    brand_img: Google_icon,
},
{
    id: '2',
    person_name : 'Toby W.',
    rating: '5',
    content: 'This is my second time using them and it’s more than worth it. Not only is it fast but very affordable and it’s so neatly done! This is more than 5 stars to me.',
    brand_img: Facebook_icon,
}
]

export default ReviewsData;