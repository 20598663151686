import React from 'react';


function PageNotFound() {
    
  return (
    <>
     <div id="error-page">
         <div class="content_data">
            <h2 class="header" data-text="404">
               404
            </h2>
            <h4 data-text="Opps! Page not found">
               Opps! Page not found
            </h4>
            <p>
               Sorry, the page you're looking for doesn't exist. If you think something is broken, report a problem.
            </p>
         </div>
      </div>
    </>
  )
}

export default PageNotFound;